import React, { MouseEventHandler, ReactElement } from 'react'
import './Button.css'

interface Props {
  style: 'primary' | 'secondary'
  title: string
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
}

const Button: React.FC<Props> = ({
  style,
  title,
  onClick,
  className = '',
  disabled = false,
  type = 'button'
}): ReactElement => {
  const buttonStyle = `rounded-full text-base px-xl py-xs md:px-2xl md:py-sm ${
    style === 'primary' && disabled
      ? 'bg-primary text-white opacity-50'
      : style === 'primary' && !disabled
      ? 'bg-primary text-white'
      : style === 'secondary' && disabled
      ? 'border bg-white text-black opacity-50'
      : 'border bg-white text-black'
  } ${className}`
  return (
    <button
      id={title}
      type={type}
      className={buttonStyle}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  )
}

export default Button
