import React, { ReactElement } from 'react'
import H3 from '../Header/H3'
import SmallText from '../text/SmallText'

interface Props {
  title: string
  description?: string
  children?: React.ReactNode
}

const InformationBox = ({
  title,
  description,
  children
}: Props): ReactElement => {
  return (
    <div className="bg-backgroundLight dark:bg-stone-950 dark:border-zinc-800 rounded-md p-md border">
      <H3>{title}</H3>
      {description && (
        <SmallText className="mt-sm" style="secondary">
          {description}
        </SmallText>
      )}
      {children && <div className="mt-sm">{children}</div>}
    </div>
  )
}

export default InformationBox
