// import { TrashIcon } from '@heroicons/react/24/outline'
import React, { ReactElement } from 'react'
import { UploadRequirement } from '../../dto/UploadRequirement.interface'
import { uploadRequirementsDefault } from '../../data/uploadRequirements'
import { Document, Page, pdfjs } from 'react-pdf'
import { TrashIcon } from '@heroicons/react/24/solid'
import InformationList from '../information/InformationList'
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  file: any
  onDelete?: () => void
  requirementsIn?: UploadRequirement[]
  withoutBackground?: boolean
  withoutPadding?: boolean
}

const FilePreview: React.FC<Props> = ({
  file,
  requirementsIn,
  onDelete,
  withoutBackground = false,
  withoutPadding = false
}: Props): ReactElement => {
  const requirements: UploadRequirement[] =
    requirementsIn ?? uploadRequirementsDefault

  const renderPreviewFromFile = (): ReactElement => {
    const isString = typeof file === 'string'

    if (isString) {
      if (
        file.includes('png') ||
        file.includes('jpg') ||
        file.includes('jpeg')
      ) {
        return (
          <div className="rounded-lg border-0 h-60 overflow-auto w-full flex justify-center">
            <img
              src={file}
              className="rounded-lg border-0 w-full object-contain"
            />
          </div>
        )
      }
      return <iframe src={file} className="rounded-lg border-0 h-60 w-full" />
    }
    if (file.type === 'application/pdf') {
      return (
        <Document
          file={file}
          className="rounded-lg border-0 h-60 overflow-auto w-full flex justify-center"
          onLoadSuccess={({ numPages }) => {
            // setPageNumber(numPages)
          }}
        >
          <Page
            pageNumber={1}
            className="w-full"
            renderAnnotationLayer={false}
            renderInteractiveForms={false}
            renderTextLayer={false}
          />
        </Document>
      )
    }

    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    ) {
      return (
        <div className="flex w-full justify-center items-center">
          <img
            src={URL.createObjectURL(file)}
            className="rounded-lg border-0 h-60 w-full overflow-auto object-scale-down"
          />
        </div>
      )
    }

    return (
      <iframe
        src={URL.createObjectURL(file)}
        className="rounded-lg border-0 h-60 w-full"
      />
    )
  }

  return (
    <>
      <div
        className={`${
          withoutBackground ? '' : 'bg-backgroundDark'
        } rounded-lg relative`}
      >
        <div className={!withoutPadding ? 'p-2' : ''}>
          {renderPreviewFromFile()}

          {onDelete && (
            <div
              className="p-3 rounded-full bg-white absolute top-0 right-0 m-3 shadow-md cursor-pointer"
              onClick={onDelete}
            >
              <TrashIcon className="w-4 h-4 text-black" />
            </div>
          )}
        </div>
      </div>
      {requirements.length > 0 && (
        <div className="my-lg">
          <InformationList
            informationList={requirements}
            title="What you need:"
          />
        </div>
      )}
    </>
  )
}

export default FilePreview
