import React, { ReactElement } from 'react'

interface Props {
  children?: React.ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  style?: 'primary' | 'secondary'
}

const Text = ({
  children,
  className = '',
  onClick,
  style = 'primary'
}: Props): ReactElement => {
  return (
    <div
      onClick={onClick}
      className={`text-sm lg:text-base ${className} ${
        style === 'secondary' ? 'text-grayText' : 'text-black'
      }`}
    >
      {children}
    </div>
  )
}

export default Text
