import React, { ReactElement } from 'react'

interface Props {
  className?: string
  children?: React.ReactNode
}

const H5 = ({ children, className = '' }: Props): ReactElement => {
  return (
    <h5 className={`text-xs md:text-sm font-semibold ${className}`}>
      {children}
    </h5>
  )
}

export default H5
