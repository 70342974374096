import { EnvelopeIcon } from '@heroicons/react/24/solid'
import { observer } from 'mobx-react'
import React, { ReactElement } from 'react'
import userStore from '../stores/user.store'
import { onPromise } from '../util/formHelper'
import CardScrollable from '../components/CardScrollable/CardScrollable'
import { Content } from '../components/content/Content'
import { useNavigate } from 'react-router-dom'
import H2 from '../components/Header/H2'
import Text from '../components/text/Text'

const SuccessLoginAndRegistrationPage: React.FC = (): ReactElement => {
  const navigate = useNavigate()
  const user = userStore.getUser()

  if (!user || !user.email) {
    navigate('/')
    return <></>
  }

  const resend = async (): Promise<void> => {
    try {
      if (user && user.email) {
        await userStore.registerOrLoginUser({
          data: { email: user.email },
          isResend: true
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const description = `We have sent you a link via email to verify your account at ${user.email}.`

  return (
    <CardScrollable title="Login or Register" logoutVisible={false}>
      <Content className="justify-center items-center">
        <div className="flex-grow flex flex-col justify-center items-center">
          <div className="bg-primary rounded-full p-5 mb-md">
            <EnvelopeIcon className="w-14 h-14" color="white" />
          </div>
          <H2 className="mb-sm">Verify and activate your account</H2>
          <Text className="max-w-md text-center" style="secondary">
            {description}
          </Text>
        </div>
        <Text
          className="text-sm underline mt-6 cursor-pointer dark:text-white"
          onClick={onPromise(resend)}
        >
          Didn&apos;t get an email? Send it again!
        </Text>
      </Content>
    </CardScrollable>
  )
}

export default observer(SuccessLoginAndRegistrationPage)
