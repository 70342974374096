import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CardScrollable from '../components/CardScrollable/CardScrollable'
import Checkbox from '../components/checkbox/Checkbox'
import TextInput from '../components/inputs/TextInput'
import TermsOfService from '../components/termsofservice/TermsOfService'
import LoginDto from '../dto/Login.dto'
import userStore from '../stores/user.store'
import { onPromise } from '../util/formHelper'
import queryString from 'query-string'
import InputOptionType from '../enums/InputOptionType'
import configStore from '../stores/config.store'

const description: string =
  'Please enter your email address to log in or register if you do not have an account yet.'

const LoginAndRegistrationPage: React.FC = (): ReactElement => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const methods: UseFormReturn<LoginDto, any> = useForm<LoginDto>({
    mode: 'onSubmit'
  })

  useEffect(() => {
    if (location.search !== '') {
      const values = queryString.parse(location.search)

      const verifyTokensforLoginOrRegistration = async (
        search: string
      ): Promise<void> => {
        const token = values.token
        const verificationToken = values.verificationToken
        const redirect = values.redirect
        delete values.token
        delete values.verificationToken

        try {
          if (token && verificationToken) {
            await userStore.verifyLoginOrRegistrationToken({
              token: token.toString(),
              verificationToken: verificationToken.toString(),
              redirect: redirect ? redirect.toString() : '',
              navigate,
              params: values
            })

            if (configStore.config?.iframeMode) {
              window.parent.postMessage('AUTH_SUCCESS', '*')
            }
          }
        } catch (error) {
          if (configStore.config?.iframeMode) {
            window.parent.postMessage('AUTH_FAILED', '*')
          }
          console.error(error)
        }
      }
      verifyTokensforLoginOrRegistration(location.search).catch((error) =>
        console.error(error)
      )
    }
  }, [])

  const onSubmit: SubmitHandler<LoginDto> = async (data) => {
    try {
      setIsLoading(true)
      // boolean values
      if (typeof data.isBeneficialOwnerAccepted === 'string') {
        data.isBeneficialOwnerAccepted =
          data.isBeneficialOwnerAccepted === 'true'
      }

      if (typeof data.isTermsAndConditionsAccepted === 'string') {
        data.isTermsAndConditionsAccepted =
          data.isTermsAndConditionsAccepted === 'true'
      }
      await userStore.registerOrLoginUser({ data, navigate })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <CardScrollable
      title="Login or Register"
      logoutVisible={false}
      description={description}
      loading={isLoading}
      formMethods={methods}
      formOnSubmit={onPromise(methods.handleSubmit(onSubmit))}
      primaryBtn={{
        title: 'Continue',
        type: 'submit'
      }}
    >
      <div className="flex flex-col h-full justify-between">
        <TextInput
          variable="email"
          optionType={InputOptionType.EMAIL}
          title="Email address"
          className="w-full flex-none"
        />
        <div>
          {configStore.config?.flowType === 'KYC' && (
            <Checkbox
              variable="isBeneficialOwnerAccepted"
              className="flex-none mr-2"
              text="I confirm that I am the beneficial owner of this account."
            />
          )}

          <TermsOfService />
        </div>
      </div>
    </CardScrollable>
  )
}

export default observer(LoginAndRegistrationPage)
