/* eslint-disable multiline-ternary */
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Control, Controller, useFormContext } from 'react-hook-form'
import './DateField.css'
import { DayPicker, useInput } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { usePopper } from 'react-popper'
import { CalendarIcon, XMarkIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import TextInputSkeleton from './TextInputSkeleton'
import SmallText from '../text/SmallText'

interface Props {
  variable: any
  control: Control<any, any>
  title?: string
  className?: string
  loading?: boolean
  selectedDate?: Date | undefined
  disabled?: boolean
  onChange?: () => Promise<void>
  initialDate?: string | Date | undefined
  hideTitle?: boolean
  hidePlaceholder?: boolean
  required?: boolean
  hideError?: boolean
}
const DateField: React.FC<Props> = ({
  variable,
  control,
  loading,
  title = 'Date',
  className = '',
  disabled,
  initialDate,
  hideTitle = false,
  hidePlaceholder = false,
  required = false,
  hideError = false
}): ReactElement => {
  const {
    formState: { errors }
  } = useFormContext()

  const { dayPickerProps, setSelected } = useInput({
    defaultSelected: new Date(),
    fromYear: 1920,
    toYear: dayjs().year(),
    format: 'dd.MM.yyyy',
    required
  })

  let error: any = errors
  variable.split('.').forEach((v: string) => {
    if (error && error[v]) {
      error = error[v]
    } else {
      error = null
    }
  })

  useEffect(() => {
    if (initialDate && dayjs(initialDate, 'DD.MM.YYYY', true).isValid()) {
      setSelected(dayjs(initialDate, 'DD.MM.YYYY', true).toDate())
    }
  }, [initialDate])

  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleButtonClick = (): void => {
    if (!disabled) {
      setIsPopperOpen(true)
    }
  }

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  })

  const closePopper = (): void => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }

  return (
    <>
      <Controller
        name={variable}
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => {
          if (loading) {
            return (
              <>
                <TextInputSkeleton /> <div className="h-7"></div>
              </>
            )
          }

          return (
            <div className="relative text-black">
              {!hideTitle && (
                <SmallText style="secondary" className="mb-1">
                  {title}
                </SmallText>
              )}
              <div ref={popperRef} className="relative overflow-hidden">
                <input
                  className={`border rounded-md bg-backgroundLight dark:text-white dark:bg-stone-950 dark:border-stone-950 overflow-hidden flex px-2.5  ${
                    disabled ? 'opacity-80' : ''
                  } focus:border-primary outline-0 h-10 ${
                    error != null ? 'border-error focus:border-error' : ''
                  } ${className}`}
                  disabled={disabled}
                  onChange={(event) => {
                    const date = event.target.value

                    if (dayjs(date, 'DD.MM.YYYY', true).isValid()) {
                      setSelected(
                        dayjs(event.target.value, 'DD.MM.YYYY').toDate()
                      )
                    }
                    if (dayjs(date, 'YYYY/MM/DD', true).isValid()) {
                      setSelected(
                        dayjs(event.target.value, 'YYYY/MM/DD').toDate()
                      )
                    }
                    onChange(event.target.value)
                  }}
                  value={value}
                  placeholder={!hidePlaceholder ? title : undefined}
                  autoComplete="none"
                  type="text"
                />
                <div
                  className="pa2 cursor-pointer button-reset ba h-full flex justify-center items-center absolute right-0 top-0 bottom-0 mr-3"
                  aria-label="Pick a date"
                  onClick={handleButtonClick}
                >
                  <span role="img" aria-label="calendar icon">
                    <CalendarIcon
                      className="dark:text-white"
                      color={disabled ? 'rgb(107 114 128)' : 'black'}
                      width={22}
                      height={22}
                    />
                  </span>
                </div>
              </div>
              {isPopperOpen && (
                <div
                  className="dialog-sheet bg-white right-0 shadow rounded mt-3 z-50 absolute"
                  {...popper.attributes.popper}
                  ref={setPopperElement}
                  role="dialog"
                >
                  <DayPicker
                    initialFocus={isPopperOpen}
                    weekStartsOn={1}
                    {...dayPickerProps}
                    mode="single"
                    onSelect={(day) => {
                      onChange(dayjs(day).format('DD.MM.YYYY'))
                      setIsPopperOpen(false)
                    }}
                    captionLayout="dropdown"
                  />
                  <div
                    className="p-1 absolute cursor-pointer top-0 right-0"
                    onClick={() => {
                      closePopper()
                    }}
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </div>
                </div>
              )}
              {!hideError && error != null && error?.type !== 'required' ? (
                <div className="text-error">{error?.message as ReactNode}</div>
              ) : (
                <div className="h-3"></div>
              )}
            </div>
          )
        }}
      />
    </>
  )
}

export default DateField
