import { XMarkIcon } from '@heroicons/react/24/solid'
import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useState } from 'react'
import { VerificationStepStatus } from '../../enums/VerificationStepStatus'
import userStore from '../../stores/user.store'
import Footer from '../footer/Footer'

interface Props {
  children: any
  className?: string
  onClick?: () => void
  hideFooter?: boolean
}

const Card: React.FC<Props> = ({
  children,
  className = '',
  onClick,
  hideFooter = false
}: Props): ReactElement => {
  const [isRejected, setIsRejected] = useState(false)

  useEffect(() => {
    if (!userStore.user) {
      return
    }

    if (userStore.user?.status === VerificationStepStatus.REJECTED) {
      setIsRejected(true)
    }
  }, [userStore.user])

  return (
    <div className="md:h-5/6 w-full h-full md:w-1/2 md:min-w-[700px] max-w-[840px] flex flex-col">
      <div
        id="card"
        className={`bg-white dark:bg-neutral-900 dark:text-white w-full self-center md:rounded-2xl rounded-none flex-grow flex flex-col overflow-hidden ${className}`}
        onClick={onClick}
      >
        {!isRejected && children}
        {isRejected && (
          <div className="flex flex-col justify-center items-center h-full">
            <XMarkIcon color="red" className="w-12 h-12" />
            Your submission has been rejected.
          </div>
        )}
      </div>
      <div className="hidden md:block">{!hideFooter && <Footer />}</div>
    </div>
  )
}

export default observer(Card)
