import React, { ReactElement } from 'react'
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import GeneralHeader from './GeneralHeader'
import InformativeHeader from './InformativeHeader'
import Text from '../text/Text'

interface Props {
  className?: string
  heading: string
  description?: string
  classNameInformativeHeader?: string
  logoutVisible?: boolean
  hideBorder?: boolean
}

const CardHeader: React.FC<Props> = ({
  className = '',
  heading,
  description,
  classNameInformativeHeader = '',
  logoutVisible = true,
  hideBorder = false
}: Props): ReactElement => {
  const navigate = useNavigate()

  const logoutUser = (): void => {
    localStorage.clear()
    navigate('/')
  }

  return (
    <div className={`${className}`}>
      <div
        className={`flex flex-row p-l md:p-xl pb-md md:pb-lg items-center ${
          !hideBorder ? 'border-b dark:border-zinc-800' : ''
        }`}
      >
        <GeneralHeader className="mr-3 box-border" />
        <InformativeHeader
          heading={heading}
          className={`grow ${classNameInformativeHeader}`}
        />
        {logoutVisible && (
          <div
            className="flex justify-center items-center"
            id="btn-logout"
            onClick={logoutUser}
          >
            <ArrowLeftOnRectangleIcon className="cursor-pointer h-6 w-6 self-end ml-2" />
          </div>
        )}
      </div>
      {description && (
        <Text className="px-l md:px-xl pt-md md:pt-lg dark:text-white">
          {description}
        </Text>
      )}
    </div>
  )
}

export default CardHeader
