import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'
import Text from '../text/Text'

interface Props {
  text?: any
  className?: string
  variable: string
  required?: boolean
}

const Checkbox: React.FC<Props> = ({
  text,
  className = '',
  variable,
  required = true
}: Props): ReactElement => {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext()

  const isChecked = watch(variable) ?? false

  const checkboxClass = classNames({
    'appearance-none relative mr-3 outline-none cursor-pointer h-6 w-6 min-w-[24px] rounded border':
      true,
    'bg-primary before:content-checkIcon before:absolute before:w-full before:h-full before:flex before:items-center before:justify-center':
      isChecked,
    'input-error': errors[variable] != null
  })

  const requiredReg: any = {}
  if (required) {
    requiredReg.required = 'Checkbox required.'
  }

  return (
    <div className={className}>
      <div className="flex flex-row items-center">
        <input
          {...register(variable, requiredReg)}
          type="checkbox"
          className={checkboxClass}
          id={variable}
          value={isChecked}
          onChange={(e) => {
            clearErrors(variable)
            setValue(variable, e.target.checked)
          }}
        />
        <label
          htmlFor={variable}
          className={`cursor-pointer ${
            errors[variable] != null ? 'text-error' : ''
          }`}
        >
          <Text className="dark:text-white">{text}</Text>
        </label>
      </div>
      {/* <div className='h-5'>{errors[variable] != null && <div className='text-red-600'>{errors[variable]?.message as ReactNode}</div>}</div> */}
    </div>
  )
}

export default Checkbox
